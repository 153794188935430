<template>
  <div>
    <form class="form" @submit.stop.prevent="formOnsubmit()">
      <!--begin: Wizard Step 1-->
      <div
        class="pb-5"
        data-wizard-type="step-content"
        data-wizard-state="current"
      >
        <h5 class="font-weight-bolder text-dark font-size-h6 font-size-h3-lg">
          <span>
            {{ title }}
          </span>
        </h5>

        <b-form-group
          id="input-group-code"
          label="Kode:"
          label-for="input-code"
        >
          <b-form-input
            id="input-code"
            v-model="form.code"
            placeholder="Kode Semester"
          ></b-form-input>
          <small class="text-danger">{{ error.code }}</small>
        </b-form-group>

        <b-form-group
          id="input-group-name"
          label="Nama:"
          label-for="input-name"
        >
          <b-form-input
            id="input-name"
            v-model="form.name"
            placeholder="Nama Semester"
          ></b-form-input>
          <small class="text-danger">{{ error.name }}</small>
        </b-form-group>

        <b-form-group
          id="input-group-school-year"
          label="Tahun Ajaran:"
          label-for="input-school-year"
        >
          <treeselect
            v-model="form.school_year_id"
            :multiple="false"
            placeholder="Pilih Tahun Ajaran"
            :options="school_years"
          />
          <small class="text-danger">{{ error.school_year_id }}</small>
        </b-form-group>
      </div>
      <!--end: Wizard Step 1-->

      <!--begin: Wizard Actions -->
      <div class="d-flex justify-content-between border-top pt-10">
        <div>
          <b-button type="submit" variant="primary">Simpan</b-button>
          <b-button
            type="button"
            class="ml-2"
            variant="default"
            @click="$router.push('/masters/semesters')"
          >
            Batal
          </b-button>
        </div>
      </div>
      <!--end: Wizard Actions -->
    </form>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  props: {
    form: Object,
    route: String,
    title: String,
  },
  data() {
    return {
      error: {
        name: "",
        code: "",
        school_year_id: "",
      },
      school_years: [],
    };
  },
  methods: {
    async getSchoolYearOption() {
      let response = await module.setTreeSelect("api/school-years");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.school_years = response.data;
        this.school_years.unshift({
          label: "Pilih Tahun Ajaran",
          id: "",
          isDisabled: true,
        });
      }
    },

    async formOnsubmit() {
      // Make Request
      let response = await module.submit(this.form, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire({
          title: response.success.title,
          text: response.success.message,
          icon: "success",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.$router.push("/masters/semesters");
      }
    },
  },
  mounted() {
    this.getSchoolYearOption();
  },
};
</script>