const ValidationModule = {

    setValidationError(error, validationError) {
      this.clearValidationError(error)
      for (const [key, value] of Object.entries(validationError)) {
        error[key] = value[0]
      }
      Swal.fire({
        title: 'Gagal',
        text: 'Harap periksa kembali form',
        icon: 'error',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true
      })
      return error
    },
  
    clearValidationError(error) {
      for (const [key] of Object.entries(error)) {
        error[key] = ''
      }
      return error
    },
  
    clearForm(form) {
      for (const [key] of Object.entries(form)) {
        form[key] = ''
      }
      return form
    }
  
  }
  
  export default ValidationModule